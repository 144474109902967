<template>
  <div id="intro" class="sub_wrap">
    <section class="sub_sec">
      <SubPageExplain>
        <template>
          <strong class="title">잃어버린 내 휴대폰을 빠르고<br />간편하게 찾을 수 있는 방법 없을까요?</strong>
        </template>
        <template v-solt:desc>
          <p>
            휴대폰을 잃어버린 경험이 있는 분들이라면 한 번쯤은 생각해 봤을 분실 시 있었으면 하는 기능들, 휴대폰 분실
            보호(MFinder)에서 모두 이용하실 수 있습니다.
          </p>
        </template>
      </SubPageExplain>
      <IntroTabGroup :introTabGroup="introTabGroup" @changeIntroTab="changeIntroTab" />
    </section>
  </div>
</template>

<script>
import SubPageExplain from "@/components/SubPageExplain.vue"
import IntroTabGroup from "@/components/Intro/IntroTabGroup.vue"

export default {
  name: "Intro",
  components: {
    SubPageExplain,
    IntroTabGroup,
  },
  mounted() {
    window.addEventListener("scroll", this.$handleFixDown)
  },
  destroyed() {
    window.removeEventListener("scroll", this.$handleFixDown)
  },
  data() {
    return {
      introTabGroup: [
        {
          actIdx: 0,
          title: "<i>회수시도</i>내 휴대폰,<br />누가 가지고 있을까?",
          menu: {
            textOrder: 0,
            btns: ["카메라실행", "잠금 메세지", "비상 연락처", "유심변경 감지"],
            desc: [
              {
                text: "원격제어 웹을 통해 응답이 없어도 분실 휴대폰의 카메라와  마이크를 실행합니다. 실시간 화면을 촬영하거나 녹화할 수 있으며  회수 요청 음성을 전달할 수 있습니다.",
                imgPath: require("@/assets/images/intro_tab1_1.png"),
              },
              {
                text: "원격제어 웹을 통해 분실 휴대폰의 잠금모드 상태에서 표시될 메세지를 입력할 수 있습니다.",
                imgPath: require("@/assets/images/intro_tab1_2.png"),
              },
              {
                text: "원격제어 웹과 모바일 앱을 통해 비상연락처를 등록할 수 있습니다. 잠금모드 화면에서 통화버튼을 누르면 등록된 연락처와 통화를 연결합니다.",
                imgPath: require("@/assets/images/intro_tab1_3.png"),
              },
              {
                text: "원격제어 웹을 통해  분실 휴대폰의 SIM카드 변경 이력을 확인할 수 있습니다. 변경된 유심의 전화번호를 통해 누가 사용하고 있는지 알 수 있습니다.",
                imgPath: require("@/assets/images/intro_tab1_4.png"),
              },
            ],
          },
        },
        {
          actIdx: 0,
          title: "<i>정보보호</i>내 개인정보<br />어떻게 보호하지?",
          menu: {
            textOrder: 0,
            btns: ["휴대폰잠금", "백업", "복원", "삭제"],
            desc: [
              {
                text: "원격제어 웹에 로그인과 동시에 분실 휴대폰에 잠금모드가  실행되며, 미리 설정한 PIN번호를 입력하지 않으면  전원 OFF를 포함한 휴대폰의 기능을 사용할 수 없습니다.",
                imgPath: require("@/assets/images/intro_tab2_1.png"),
              },
              {
                text: "원격제어 웹을 통해 분실 휴대폰의 사진/동영상, 연락처 등 개인 데이터를 안전하게 백업 할 수 있습니다. (분실 전, MFinder 앱을 통해 자동백업 설정도 가능)",
                imgPath: require("@/assets/images/intro_tab2_2.png"),
              },
              {
                text: "분실한 휴대폰을 회수하셨나요? 백업해둔 기존 데이터를 모바일 앱을 통해 복원할 수 있습니다.",
                imgPath: require("@/assets/images/intro_tab2_3.png"),
              },
              {
                text: "개인정보가 걱정된다면, 원격제어 웹을 통해 분실 휴대폰의 데이터를 모두 삭제 할 수 있습니다. (미리 데이터 백업을 진행 한 후에 진행하시기 바랍니다.)",
                imgPath: require("@/assets/images/intro_tab2_4.png"),
              },
            ],
          },
        },
        {
          actIdx: 0,
          title: "<i>위치찾기</i>내 휴대폰,<br />지금 어디에 있지?",
          menu: {
            textOrder: 0,
            btns: ["위치조회", "사이렌울리기", "WIFI감지"],
            desc: [
              {
                text: "원격제어 웹을 통해 분실 휴대폰의 실시간 현재 위치를  확인하고, 분실 이후의 시간 별로 이동경로를 조회할 수  있습니다.",
                imgPath: require("@/assets/images/intro_tab3_1.png"),
              },
              {
                text: "원격제어 웹을 통해 분실 휴대폰의 사운드를 실행합니다. 1분간 최대 볼륨으로 선택된 사운드를 울려 근처에 내 휴대폰이 있는지 확인해 볼 수 있습니다. ",
                imgPath: require("@/assets/images/intro_tab3_2.png"),
              },
              {
                text: "원격제어 웹을 통해 분실된 휴대폰 주변의 WIFI 네트워크를 감지합니다. 이 목록을 통해 휴대폰의 현재 위치를 예상해 볼 수 있습니다. ",
                imgPath: require("@/assets/images/intro_tab3_3.png"),
              },
            ],
          },
        },
        {
          actIdx: 0,
          title:
            "<i>휴대폰도난/분실 결제 손해 보상 보험</i>분실폰에서 금전 피해 발생시 보상 한도 100만원까지 보상해드립니다.",
          menu: {
            textOrder: 1,
            desc: [
              {
                text: "휴대폰 도난 또는 분실 시 해당 단말기에서 무단 결제가 발생했을 경우 사고 입증자료(경찰신고서류 등)를 제출 시 서비스 이용자에게 1인 1사고당 보험한도액 100만원까지 보상해드립니다. 보험금 지급 시 자기부담금(20만원)이 발생하며, 증권상 최대보상한도액 초과 시 보험금이 지급되지 않을 수 있습니다.",
                imgPath: require("@/assets/images/sub-intro-tab.png"),
              },
            ],
          },
        },
      ],
    }
  },
  methods: {
    changeIntroTab(tabIdx, btnIdx) {
      this.introTabGroup[tabIdx].actIdx = btnIdx
    },
  },
}
</script>
