<template>
  <div class="page_explain">
    <slot></slot>
    <slot name="desc"></slot>
  </div>
</template>

<script>
export default {
  name: "SubPageExplain",
}
</script>
