<template>
  <div class="intro_tabgroup">
    <section v-for="(tabItem, tabIdx) in introTabGroup" :key="tabIdx" class="intro_tab">
      <strong v-html="tabItem.title" class="tab_tit" />
      <div class="tab_menu">
        <button
          v-for="(btnName, btnIdx) in tabItem.menu.btns"
          :key="btnIdx"
          type="button"
          class="tab_btn"
          :class="btnIdx === tabItem.actIdx && 'now'"
          @click="handleIntroTab(tabIdx, btnIdx)"
        >
          {{ btnName }}
        </button>
      </div>
      <div class="tab_wrap">
        <div class="tab_desc" v-for="(descItem, descIdx) in tabItem.menu.desc" :key="descIdx">
          <template v-if="descIdx === tabItem.actIdx">
            <p v-show="tabItem.menu.textOrder == 0" v-html="descItem.text" />
            <span class="img"><img :src="descItem.imgPath" alt="" /></span>
            <p class="btm_p" v-show="tabItem.menu.textOrder == 1" v-html="descItem.text" />
          </template>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "IntroTabGroup",
  props: {
    introTabGroup: {
      type: Array,
    },
    changeIntroTab: {
      type: Function,
    },
  },
  computed: {},
  methods: {
    handleIntroTab(tabIdx, btnIdx) {
      this.$emit("changeIntroTab", tabIdx, btnIdx)
    },
  },
}
</script>
